import React from 'react';
import App from '../src/components/App';
import { namespace } from './configs/projectNames';

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root(props) {
  return (
    <section id={namespace.name} className={namespace}>
      <App {...props} />
    </section>
  );
}
