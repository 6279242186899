import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from '@jarvis/react-portal-addons';
import { ThemeProvider, useTheme } from '@veneer/theme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import ToastProvider from '../ToastProvider';
import resources from '../../assets/locale/index';

const StyledThemeProviderWrapper = ({ children }) => {
  const theme = useTheme();

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

StyledThemeProviderWrapper.propTypes = {
  children: PropTypes.node,
};

StyledThemeProviderWrapper.defaultProps = {
  children: null,
};

const Providers = ({
  children, shell, ...props
}) => (
  <RootProvider
    {...props}
    shell={shell.v1}
    localization={shell.v1?.localization}
    resources={resources}
  >
    <ThemeProvider>
      <StyledThemeProviderWrapper>
        <ToastProvider>
          {children}
        </ToastProvider>
      </StyledThemeProviderWrapper>
    </ThemeProvider>
  </RootProvider>
);

Providers.propTypes = {
  children: PropTypes.node,
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
};

Providers.defaultProps = {
  children: null,
};

export default Providers;
