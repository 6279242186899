import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { LoaderWidget } from '@jarvis/react-portal-addons';
import Providers from '../Providers';
import {
  getLDClientSideID,
  getUniqueUserKey,
} from '../../utils/ldClient';

const FaxSolutionItem = React.lazy(() => import('../FaxSolutionItem'));
const FaxSetup = React.lazy(() => import('../FaxSetup'));
const FaxManage = React.lazy(() => import('../FaxManage'));

const MainApp = props => {
  const {
    type,
    shell: {
      v1: {
        authProvider,
        navigation,
        store,
      },
    },
    stack,
    cloudId,
  } = props;

  const componentProps = {
    ...props,
    stack,
    shell: {
      authProvider,
      navigation,
      store,
    },
    cloudId,
  };

  const getComponent = useCallback(params => {
    switch (type) {
      case 'SMBFaxSolutionItem':
        return (
          <Suspense fallback={<LoaderWidget fullscreen />}>
            <FaxSolutionItem {...params} />
          </Suspense>
        );
      case 'SMBFaxSetup':
        return (
          <Suspense fallback={<LoaderWidget fullscreen />}>
            <FaxSetup {...params} show />
          </Suspense>
        );
      case 'SMBFaxManage':
      default:
        return (
          <Suspense fallback={<LoaderWidget fullscreen />}>
            <FaxManage {...params} />
          </Suspense>
        );
    }
  }, [type]);

  return (
    <Providers {...props}>
      {getComponent(componentProps)}
    </Providers>
  );
};

MainApp.defaultProps = {
  type: 'SMBFaxManage',
  enableLocalization: true,
};

MainApp.propTypes = {
  type: PropTypes.string,
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
  stack: PropTypes.number.isRequired,
  cloudId: PropTypes.string.isRequired,
  enableLocalization: PropTypes.bool,
};

const App = props => {
  const [LDProvider, setLDProvider] = useState(null);
  const [fetchingLD, setFetchingLD] = useState(true);
  const [errorLD, setErrorLD] = useState(false);

  const locale = props.shell?.v1?.localization?.locale;

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const Provider = await asyncWithLDProvider({
          clientSideID: getLDClientSideID(),
          options: {
            bootstrap: 'localStorage',
            streaming: false,
          },
          reactOptions: {
            useCamelCaseFlagKeys: true,
          },
          user: {
            key: getUniqueUserKey(locale),
          },
        });
        setLDProvider(() => Provider);
      } catch (err) {
        console.error(err);
        setErrorLD(true);
      } finally {
        setFetchingLD(false);
      }
    };
    fetchFetureFlags();
  }, [locale]);

  if (fetchingLD) {
    return <LoaderWidget fullScreen />;
  }

  if (errorLD) {
    return <MainApp {...props} />;
  }

  return (
    <LDProvider>
      <MainApp {...props} />
    </LDProvider>
  );
};

App.defaultProps = {
  active: true,
  container: document.body,
  disableContainerPadding: true,
  enableLocalization: true,
  onSetupCancel: () => {},
  onSetupComplete: () => {},
  showSetupFlow: false,
};

App.propTypes = {
  active: PropTypes.bool,
  container: PropTypes.element,
  disableContainerPadding: PropTypes.bool,
  enableLocalization: PropTypes.bool,
  onSetupCancel: PropTypes.func,
  onSetupComplete: PropTypes.func,
  showSetupFlow: PropTypes.bool,
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default App;
